// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.character > img {
    width: 50px;
    height: 50px;
    float: left;
    margin: auto;
}

.character > p {
    margin: auto;
    margin-left: 60px;
    width: 150px;
}

.character {
    display: inline-block;
}

.button {
    margin-bottom : 5px !important;
}

.delete_button{
    height: 15px;
    width: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/BuildingForm/CercleScreen/CercleScreen.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".character > img {\n    width: 50px;\n    height: 50px;\n    float: left;\n    margin: auto;\n}\n\n.character > p {\n    margin: auto;\n    margin-left: 60px;\n    width: 150px;\n}\n\n.character {\n    display: inline-block;\n}\n\n.button {\n    margin-bottom : 5px !important;\n}\n\n.delete_button{\n    height: 15px;\n    width: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
