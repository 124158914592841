import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton,
  MRT_Row,
  MRT_ShowHideColumnsButton,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";
import { Presence } from "../../model/Presence";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { ExportToCsv } from "export-to-csv";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import SectorContext from "../../context/SectorContext";

const PresencesList = () => {
  const [axiosInstance] = useAxios();
  const [presences, setPresences] = useState<Presence[]>([]);
  const { sector } = useContext(SectorContext);

  const getPresences = 
    async (date: string) => {
      axiosInstance
        .get<Presence[]>(`${baseUrl}/presences`, { params: { date, idSector: sector } })
        .then((response) => setPresences(response.data));
    };

  const [date, setDate] = useState(
    new Date(Date.now()).toLocaleDateString("en-CA")
  );

  useEffect(() => {
    getPresences(date);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, sector]);

  const ColumnDefinition = useMemo<MRT_ColumnDef<Presence>[]>(
    () => [
      {
        accessorFn: (row) => format(new Date(row.date!), "yyyy-MM-dd"), //convert to Date for sorting and filtering
        accessorKey: "date",
        header: "Date",
        size: 25,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "date",
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorFn: (row) => row.batiment?.idLieu, //convert to Date for sorting and filtering
        header: "Id Lieu",
        size: 15,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "number",
          disabled: true,
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorFn: (row) => row.batiment?.adresse, //convert to Date for sorting and filtering
        header: "Adresse",
        size: 35,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "string",
          disabled: true,
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorFn: (row) => row.batiment?.nom, //convert to Date for sorting and filtering
        header: "Nom",
        size: 35,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "string",
          disabled: true,
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorKey: "sousStructure",
        header: "Sous-Structure",
        size: 35,
        enableSorting: false,
        Cell: ({ cell }) => cell.getValue<number>() ?? "-", //render Date as a string
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorFn: (row) => row.batiment?.digicode, //convert to Date for sorting and filtering
        header: "Digicode",
        size: 35,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "string",
          disabled: true,
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 35,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "note",
        header: "Note",
        size: 150,
        enableSorting: false,
        Cell: ({ cell }) => cell.getValue<string>(), //render Date as a string
        muiEditTextFieldProps: {
          type: "string",
        },
      },
      {
        accessorKey: "checked",
        header: "Vérifié",
        size: 35,
        enableSorting: false,
        Cell: ({ cell }) => (
          <Checkbox disabled checked={cell.getValue<boolean>()} />
        ),
        muiEditTextFieldProps: () => ({
          children: [
            { key: "Oui", value: "true" },
            { key: "Non", value: "false" },
          ].map((state) => (
            <MenuItem key={state.key} value={state.value}>
              {state.key}
            </MenuItem>
          )),
          select: true,
        }),
      },
    ],
    []
  );

  const handleUpdatePresence = async ({
    exitEditingMode,
    row,
    values,
  }: {
    exitEditingMode: () => void;
    row: MRT_Row<Presence>;
    values: Presence;
  }) => {
    await axiosInstance.put(
      `${baseUrl}/presence/${(row?.original as any).id}`,
      values
    );
    getPresences(date);
    exitEditingMode();
  };

  const handleGetNextDay = () => {
    const newDate = format(
      addDays(parse(date, "yyyy-MM-dd", new Date()), 1),
      "yyyy-MM-dd"
    );
    console.log(newDate);
    setDate(newDate);
  };

  const handleGetPreviousDay = () => {
    const newDate = format(
      addDays(parse(date, "yyyy-MM-dd", new Date()), -1),
      "yyyy-MM-dd"
    );
    setDate(newDate);
  };

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `export-ardalys-${new Date(Date.now()).toLocaleString("en-US")}`,
    title: "Export Ardalys",
    headers: [
      "Date",
      "Id Lieu",
      "Adresse",
      "Nom",
      "Sous-Structure",
      "Digicode",
      "Nombre",
      "Note",
      "Vérifié",
    ],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportToCsv = async () => {
    csvExporter.generateCsv(
      presences.map((p) => {
        const { batiment, date, checked, nombre, note, sousStructure } = p;
        return {
          date,
          idLieu: batiment?.idLieu,
          adresse: batiment?.adresse,
          nom: batiment?.nom,
          sousStructure,
          digicode: batiment?.digicode,
          nombre,
          note,
          checked,
        };
      })
    );
  };

  const table = useMaterialReactTable({
    columns: ColumnDefinition,
    data: presences,
    editDisplayMode: "modal",
    enableDensityToggle: false,
    enableGlobalFilter: false, //SOON :)
    enableColumnFilters: false,
    enablePagination: false,
    enableEditing: true,
    onEditingRowSave: handleUpdatePresence,
    state: {
      density: "compact",
    },
    renderToolbarInternalActions: () => (
      <>
        <IconButton onClick={handleGetPreviousDay}>
          <ArrowLeftIcon />
        </IconButton>
        <TextField
          type="date"
          InputLabelProps={{ shrink: true }}
          label="Sélectionner une date"
          variant="standard"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <IconButton onClick={handleGetNextDay}>
          <ArrowRightIcon />
        </IconButton>
        <Tooltip title="Export to CSV">
          <IconButton onClick={handleExportToCsv}>
            <BorderAllIcon />
          </IconButton>
        </Tooltip>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    )
  });

  return (
    <Box sx={{ width: "100%", minHeight: "5rem" }}>
      <MaterialReactTable
        table={table}
  
      />
    </Box>
  );
};

export default PresencesList;
