import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import { ReactElement } from "react";

interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps): ReactElement => {
  return  children;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => (<LinearProgress />)
});

