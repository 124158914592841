import { createTheme } from "@mui/material/styles";

const AppTheme = createTheme({
  palette: {
    secondary: {
      main: "#28282b",
    },
  },
});

export default AppTheme;
