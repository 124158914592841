import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Checkbox,
  MenuItem,
} from "@mui/material";
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  useMaterialReactTable,
} from "material-react-table";
import type { MRT_ColumnDef } from "material-react-table";
import { Presence } from "../../model/Presence";
import AddIcon from "@mui/icons-material/Add";
import CreatePresenceModal from "./CreatePresenceModal";
import useAxios from "../../hooks/useAxios";
import { baseUrl } from "../../constants";

interface UserAddModalProps {
  open: boolean;
  onClose: () => void;
  lotId: number;
}

function PresenceModal({
  open,
  onClose,
  lotId,
}: UserAddModalProps): JSX.Element {
  const handleClose = () => {
    onClose();
  };

  const [axiosInstance] = useAxios();
  const [presences, setPresences] = useState<Presence[]>([]);

  useEffect(() => {
    if (!open) return;
    getPresences(lotId);
  }, [open, setPresences]);

  const getPresences = async (lotId: number) => {
    axiosInstance
      .get<Presence[]>(`${baseUrl}/batiment/${lotId}/presences`)
      .then((response) => setPresences(response.data));
  };

  const [showCreationModal, setShowCreationModal] = useState(false);

  const handleCreatePresence = async (values: Presence) => {
    console.log("create", values);
    await axiosInstance.post(`${baseUrl}/presence`, {
      ...values,
      batiment: { id: lotId },
    });
    getPresences(lotId);
    setShowCreationModal(false);
  };

  const handleUpdatePresence = async ({
    exitEditingMode,
    row,
    values,
  }: {
    exitEditingMode: () => void;
    row: MRT_Row<Presence>;
    values: Presence;
  }) => {
    await axiosInstance.put(
      `${baseUrl}/presence/${(row?.original as any).id}`,
      values
    );
    getPresences(lotId);
    exitEditingMode();
  };

  const ColumnDefinition = useMemo<MRT_ColumnDef<Presence>[]>(
    () => [
      {
        accessorFn: (row) => new Date(row.date!).toLocaleDateString("en-CA"), //convert to Date for sorting and filtering
        accessorKey: "date",
        header: "Date",
        size: 35,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "date",
          InputLabelProps: { shrink: true },
        },
      },
      {
        accessorKey: "sousStructure",
        header: "Sous-Structure",
        size: 35,
        enableSorting: false,
        Cell: ({ cell }) => (
          <Box sx={{ textAlign: "center" }}>
            {cell.getValue<number>() ?? "-"}
          </Box>
        ),
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 35,
        enableSorting: false,
        Cell: ({ cell }) => (
          <Box sx={{ textAlign: "center" }}>
            {cell.getValue<number>() ?? "-"}
          </Box>
        ),
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "note",
        header: "Note",
        size: 150,
        enableSorting: false,
        Cell: ({ cell }) => cell.getValue<string>(), //render Date as a string
        muiEditTextFieldProps: {
          type: "string",
        },
      },
      {
        accessorKey: "checked",
        header: "Vérifié",
        size: 35,
        enableSorting: false,
        Cell: ({ cell }) => (
          <Checkbox disabled checked={cell.getValue<boolean>()} />
        ),
        muiEditTextFieldProps: {
          children: [
            { key: "Oui", value: "true" },
            { key: "Non", value: "false" },
          ].map((state) => (
            <MenuItem key={state.key} value={state.value}>
              {state.key}
            </MenuItem>
          )),
          select: true,
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: ColumnDefinition,
    data: presences,
    renderToolbarInternalActions: ({ table }) => (
      <>
        {/* add your own custom print button or something */}
        <IconButton onClick={() => setShowCreationModal(true)}>
          <AddIcon />
        </IconButton>
        {/* built-in buttons (must pass in table prop for them to work!) */}
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    enableDensityToggle: false,
    enableGlobalFilter: false, //SOON :)
    state: {
      density: "compact",
    },
    enableColumnFilters: false,
    enablePagination: false,
    editDisplayMode: "modal",
    enableEditing: true,
    onEditingRowSave: handleUpdatePresence,
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography>Présences</Typography>
      </DialogTitle>
      <DialogContent>
        <>
          <Box sx={{ width: "100%", minHeight: "5rem" }}>
            <MaterialReactTable table={table} />
            <CreatePresenceModal
              columns={ColumnDefinition}
              open={showCreationModal}
              onClose={() => setShowCreationModal(false)}
              onSubmit={handleCreatePresence}
            />
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Retour
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PresenceModal;
