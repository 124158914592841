import {
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Switch,
  Button,
  Autocomplete,
  Grid,
} from "@mui/material";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./LogementScreen.css";
import { SousStructure } from "../../../model/SousStructure";
import { Logement } from "../../../model/models";
import { Proprietaire } from "../../../model/Proprietaire";
import ZoneSelector from "../../common/ZoneSelector";
import CharacterField, { AutoCompleteEntry } from "../../common/CharacterField";
import { baseUrl } from "../../../constants";
import useAxios from "../../../hooks/useAxios";

interface LogementScreenProps {
  batiment: Logement;
  handleChange: any;
  setFieldValue: any;
}

function LogementScreen({
  batiment,
  handleChange,
  setFieldValue,
}: LogementScreenProps) {
  const [expanded, setExpanded] = React.useState("");

  const handleChangePanel =
    (panel: string) => (event: unknown, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };

  const handleDeleteClick = (ss: SyntheticEvent) => {
    if (batiment !== undefined) {
      const index = Number(ss.currentTarget.id.replace("delete_", ""));
      setFieldValue(
        "batiment.sousStructures",
        batiment?.sousStructures.filter((x: SousStructure, i) => i !== index)
      );
    }
  };

  const formatProprietaires = (proprietaires: Array<Proprietaire>) => {
    return proprietaires.map((x) => `${x.proprietaire} x${x.count}`).join(",");
  };

  const handleAddClick = (ss: SyntheticEvent) => {
    if (batiment !== undefined) {
      setFieldValue(
        "batiment.sousStructures",
        batiment.sousStructures.concat({
          numero: -1,
          digicode: "*******",
          zones: Array.from({ length: 9 }, (i) => (i = false)),
        })
      );
    }
  };

  const handleProprietaireChange = (
    newValue: AutoCompleteEntry | null,
    index: number
  ) => {
    if (newValue === null) {
      setFieldValue(`batiment.sousStructures.${index}.proprietaire`, undefined);
    } else {
      setFieldValue(`batiment.sousStructures.${index}.proprietaire`, {
        id: newValue.value,
        nom: newValue.label,
      });
    }
  };

  const handleLocataireChange = (
    newValue: AutoCompleteEntry | null,
    index: number
  ) => {
    if (newValue === null) {
      setFieldValue(`batiment.sousStructures.${index}.locataire`, undefined);
    } else {
      setFieldValue(`batiment.sousStructures.${index}.locataire`, {
        id: newValue.value,
        nom: newValue.label,
      });
    }
  };

  const handleSwitchChange = (event: any) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setFieldValue(name, checked);
  };

  const [defaultAutoCompleteList, setAutoDefaultCompleteList] = useState<
    AutoCompleteEntry[]
  >([]);
  const [axiosInstance] = useAxios();
  useEffect(() => {
    axiosInstance
      .get<AutoCompleteEntry[]>(`${baseUrl}/personnages/autocomplete?take=25`)
      .then((response) => setAutoDefaultCompleteList(response.data));
  }, []);

  return (
    <div>
      {batiment?.sousStructures.map((ss: SousStructure, index: number) => {
        return (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChangePanel(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                Sous-Structure #{index + 1} {}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === `panel${index}` && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CharacterField
                      onChange={(newValue: AutoCompleteEntry) =>
                        handleProprietaireChange(newValue, index)
                      }
                      name={`batiment.sousStructures.${index}.proprietaire.nom`}
                      label="Propriétaire"
                      defaultValues={defaultAutoCompleteList}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CharacterField
                      onChange={(newValue: AutoCompleteEntry) =>
                        handleLocataireChange(newValue, index)
                      }
                      name={`batiment.sousStructures.${index}.locataire.nom`}
                      label="Locataire"
                      defaultValues={defaultAutoCompleteList}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Digicode"
                      variant="standard"
                      name={`batiment.sousStructures.${index}.digicode`}
                      onChange={handleChange}
                      value={ss.digicode}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            batiment.sousStructures[index].isDigicodeCondor
                          }
                          name={`batiment.sousStructures.${index}.isDigicodeCondor`}
                          onChange={handleSwitchChange}
                          color="primary"
                        />
                      }
                      label="Condor"
                      labelPlacement="start"
                      className="switch"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={batiment.sousStructures[index].hasCamera}
                          onChange={handleSwitchChange}
                          name={`batiment.sousStructures.${index}.hasCamera`}
                          color="primary"
                        />
                      }
                      label="Camera"
                      labelPlacement="start"
                      className="switch"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ marginLeft: "1rem", marginTop: "0.75rem" }}
                  >
                    {/* <Link>Présences</Link> */}
                  </Grid>
                  <Grid item xs={12}>
                    <ZoneSelector
                      name={`batiment.sousStructures.${index}.zones`}
                      value={batiment.sousStructures[index].zones ?? []}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <br />

                  <Button id={`delete_${index}`} onClick={handleDeleteClick}>
                    Retirer
                  </Button>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}{" "}
      <Button onClick={handleAddClick}>Ajouter</Button>{" "}
      <Typography>{`Propriétaires : ${formatProprietaires(
        batiment.proprietaires
      )}`}</Typography>
    </div>
  );
}

export default LogementScreen;
