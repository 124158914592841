import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Poste } from "../../../model/models";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import UserAddModal from "../UserAddModal";
import { baseUrl } from "../../../constants";
import useAxios from "../../../hooks/useAxios";

interface PosteFormProps {
  batimentId: number;
}

const PosteForm = ({ batimentId }: PosteFormProps): JSX.Element => {
  const [openChildModal, setOpenChildModal] = useState(false);
  const [postes, setPostes] = useState<Poste[]>([]);
  const [axiosInstance] = useAxios();

  useEffect(() => {
    (async () => {
      const postes = await axiosInstance.get<Poste[]>(
        `${baseUrl}/poste/GetByBatimentId/${batimentId}`
      );
      setPostes(postes.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batimentId]);

  const handleAddMember = (poste: Poste) => {
    const array = postes.splice(0);
    array.push(poste);
    setPostes(array);
  };

  const handleDelete = async (poste: Poste) => {
    const array = postes.splice(0);
    setPostes(array.filter((x) => x.personnage !== poste.personnage));
    return (await axiosInstance.delete<boolean>(`${baseUrl}/poste/${poste.id}`))
      .data;
  };

  return (
    <Box>
      <h2 style={{ display: "block" }}>
        Membres
        <IconButton
          className="button"
          onClick={() => {
            setOpenChildModal(true);
          }}
          aria-label="delete"
        >
          <AddIcon />
        </IconButton>
      </h2>
      <Box>
        <Grid container spacing={2}>
          {postes?.map((poste: Poste) => {
            return (
              <Grid item xs={4} key={poste.id}>
                <Box className="character">
                  <img src={poste.personnage?.avatarUrl} alt="Avatar"></img>
                  <Typography>
                    {poste.personnage?.nom}
                    <IconButton
                      className="delete_button"
                      onClick={() => handleDelete(poste)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>{poste.nom}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <br />
      </Box>
      {
        <UserAddModal
          open={openChildModal}
          setOpen={setOpenChildModal}
          batimentId={batimentId}
          handleAddMember={handleAddMember}
        />
      }
    </Box>
  );
};

export default PosteForm;
