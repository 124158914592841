enum Commands {
  connect,
  idpersonnage,
  action,
  asrt,
  nomposte,
  sauvegarde,
  habitations,
  sante,
  forme,
  statut,
  credits,
}

export default Commands;
