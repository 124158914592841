// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .grid {
    text-align: center;
    display: grid;
    grid-template-columns:  repeat(99, 20px);
    grid-template-rows:  repeat(99, 20px);
  }

  .typography{
      background-color: white;
      font-size: small;
      padding-left: 5px;
      padding-right: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/MapGrid/MapGrid.css"],"names":[],"mappings":"EAAE;IACE,kBAAkB;IAClB,aAAa;IACb,wCAAwC;IACxC,qCAAqC;EACvC;;EAEA;MACI,uBAAuB;MACvB,gBAAgB;MAChB,iBAAiB;MACjB,kBAAkB;EACtB","sourcesContent":["  .grid {\n    text-align: center;\n    display: grid;\n    grid-template-columns:  repeat(99, 20px);\n    grid-template-rows:  repeat(99, 20px);\n  }\n\n  .typography{\n      background-color: white;\n      font-size: small;\n      padding-left: 5px;\n      padding-right: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
