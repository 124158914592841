import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSelectionModel,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";

interface Entry {
  id: string;
  index: number;
  command: string;
}

interface CryoResult {
  cryos: Array<string>;
  cryoProfondes: Array<string>;
  actifs: Array<string>;
  nouveaux: Array<string>;
}

const CryoManagerPage = () => {
  const [input, setInput] = useState("");
  const [axiosInstance] = useAxios();
  const [targets, setTargets] = useState<Array<Entry>>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [isLoading, setIsLoading] = useState(false);

  const CommandColumnDefinition: GridColDef[] = [
    {
      field: "command",
      headerName: "Commande",
      width: 200,
      resizable: true,
      editable: false,
      filterable: false,
    },
  ];

  const handleSelectionChange = (params: GridCellParams, event: any) => {
    if (event.key === "ArrowDown" && params.row.index !== targets.length - 1) {
      const newSelection = targets[params.row.index + 1].id;
      setSelectionModel([newSelection]);
    }

    if (event.key === "ArrowUp" && params.row.index !== 0) {
      const newSelection = targets[params.row.index - 1].id;
      setSelectionModel([newSelection]);
    }
  };

  const handleSubmit = async () => {
    setTargets([]);
    var names = Array.from(input.matchAll(/\[[^\[\]]+?\] ([^\[\]]+)/g))
      .map((x) => x[1])
      .map((y) => y.split("~"))
      .map((z) => ({ name: z[0], id: z.length > 1 ? z[1] : undefined }));
    setIsLoading(true);
    const results = await axiosInstance.post<CryoResult>(
      `${baseUrl}/cryogenie`,
      {
        cryos: names,
      }
    );
    setIsLoading(false);
    setTargets(
      results.data.actifs.map((target, index) => ({
        id: target,
        index,
        command: `action ${target}`,
      }))
    );
    console.log(results.data);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            label="Entrez le contenu du TP ici"
            variant="filled"
            fullWidth
            multiline
            value={input}
            onChange={(event) => setInput(event.target.value)}
            minRows={30}
            maxRows={30}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          {isLoading && (
            <Box sx={{ width: "100%", height: " 100%", display: "flex" }}>
              <CircularProgress
                sx={{ margin: "auto", alignContent: "center" }}
              />
            </Box>
          )}
          {targets.length > 0 && (
            <Box>
              <Typography variant="body1" sx={{ margin: "5px" }}>
                Personnages à valider
              </Typography>
              <DataGrid
                sx={{ margin: "3rem", marginTop: 0, height: "40rem" }}
                rows={targets}
                columns={CommandColumnDefinition}
                selectionModel={selectionModel}
                rowHeight={38}
                onCellKeyDown={handleSelectionChange}
                onSelectionModelChange={setSelectionModel}
              />
            </Box>
          )}
        </Grid>
        <Button onClick={handleSubmit}>Soumettre</Button>
      </Grid>
    </>
  );
};

export default CryoManagerPage;
