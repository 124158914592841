import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import "./MapGrid.css";
import { Case } from "../../model/Case";
import {
  Popper,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Tooltip,
  Drawer,
  IconButton,
} from "@mui/material";
import BuildingForm from "../BuildingForm/BuildingForm";
import { baseUrl } from "../../constants";
// import SIIcon from "../../icons/si.png";
// import SRIcon from "../../icons/sr.png";
import useAxios from "../../hooks/useAxios";
import SearchIcon from "@mui/icons-material/Search";
import Search from "./Search/Search";
import HomeIcon from "@mui/icons-material/Home";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DecorationModal from "../BuildingForm/DecorationModal";
import axios from "axios";
import PresenceModal from "../BuildingForm/PresenceModal";
import SectorContext  from "../../context/SectorContext";

function MapGrid() {
  interface PopperPosition {
    x: number;
    y: number;
  }

  const [tiles, setBatiments] = useState<Case[]>([]);
  const [decorations, setDecorations] = useState<any>({});
  const [showDecorationModal, setShowDeclarationModal] =
    useState<boolean>(false);
  const [showPresenceModal, setShowPresenceModal] = useState<boolean>(false);
  const [searchTiles, setSearchTiles] = useState<Case[]>([]);
  const [searchParams, setSearchParams] = useState({
    request: "",
    proprietaire: true,
    locataire: true,
    membre: true,
    employe: true,
    presence: true,
    note: true,
    adresse: true,
    nom: true,
  });
  const [hoveredTile, setHoveredTile] = useState<Case>();
  const [popperPosition, setPopperPosition] = useState<PopperPosition>();
  const [anchorEl, setAnchorEl] = useState<SVGElement>();
  const [clickedTile, setClickedTile] = useState<Case>();

  const [axiosInstance] = useAxios();

  const [openDrawer, setOpenDrawer] = useState(false);

  const { sector } = useContext(SectorContext);

  const handleMouseMove = (event: SyntheticEvent<SVGElement, MouseEvent>) => {
    const x = Math.floor(event.nativeEvent.offsetX / TILE_SIZE) + viewBoxMin;
    const y = Math.floor(event.nativeEvent.offsetY / TILE_SIZE) + viewBoxMin;
    const tile = grid[x][y];

    if (hoveredTile === tile) {
      return;
    }

    setAnchorEl(event.currentTarget);
    setPopperPosition({
      x:
        (Math.floor(event.nativeEvent.offsetX / TILE_SIZE) + viewBoxMin) *
        TILE_SIZE,
      y:
        (Math.floor(event.nativeEvent.offsetY / TILE_SIZE) + viewBoxMin) *
        TILE_SIZE,
    });
    setHoveredTile(tile);
  };

  const handleMouseClick = (event: SyntheticEvent<SVGElement, MouseEvent>) => {
    const x = Math.floor(event.nativeEvent.offsetX / TILE_SIZE) + viewBoxMin;
    const y = Math.floor(event.nativeEvent.offsetY / TILE_SIZE) + viewBoxMin;
    const tile = grid[x][y];
    if (!tile) return;

    axiosInstance
      .get(`${baseUrl}/case/${tile.id}`)
      .then((x) => x.data)
      .then((x) => {
        if (x.lotId !== clickedTile?.id) {
          setClickedTile(x);
        }
      });
  };

  const handleSearch = async (event: any) => {
    console.log(event);
    setSearchParams(event);
    const searchBatiment = await axiosInstance.get<Case[]>(
      `${baseUrl}/lots/search`,
      {
        params: {
          secteurId: sector,
          ...event,
        },
      }
    );
    setSearchTiles(searchBatiment.data);
  };

  const handleResetSearch = async () => {
    setSearchTiles([]);
  };

  const handleClose = async () => {
    setClickedTile(undefined);
    const batiments = await axiosInstance.get<Case[]>(`${baseUrl}/lots?id=${sector}`);
    setBatiments(batiments.data);
  };

  const handleShowDecoration = () => {
    setShowDeclarationModal(true);
  };
  const handleCloseDecoration = () => {
    setShowDeclarationModal(false);
  };

  const handleShowPresence = () => {
    setShowPresenceModal(true);
  };

  const handleClosePresence = () => {
    setShowPresenceModal(false);
  };

  useEffect(() => {
    (async () => {
      const batiments = await axiosInstance.get<Case[]>(`${baseUrl}/lots?id=${sector}`);
      setBatiments(batiments.data);
      const decoration = await axios.get("FullWall Admins.json", {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setDecorations(
        decoration.data.batiment.reduce(
          (acc: any, x: any[]) => ({ ...acc, [x[0]]: x[1] }),
          {}
        )
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector]);

  const TILE_SIZE = 20;
  const openPopper = Boolean(hoveredTile);
  const openModal = Boolean(clickedTile);

  /*return <div className="grid">
       {tiles.map((tile) => {
           return (<div key={tile.id} style={{gridColumnStart : tile.x, gridRowStart: tile.y}} className={tile.batiment?.categorie?.toLocaleLowerCase().replace(/\W+/g, '-') ?? "node"}></div>)
       })}
   </div>*/

  const grid = Array.from({ length: 100 }, () => [] as Case[]);
  tiles.forEach((t) => (grid[t.x][t.y] = t));

  const tilePath = ({ x, y }: { x: number; y: number }) =>
    `M ${x},${y} h 1 v 1 h -1 v -1 `;
  const cerclePath = tiles
    .filter((x) => x.batiment?.categorie === "Cercle")
    .map(tilePath)
    .join("");
  const entreprisePath = tiles
    .filter((x) => x.batiment?.categorie === "Entreprise")
    .map(tilePath)
    .join("");
  const annexePath = tiles
    .filter((x) => x.batiment?.categorie === "Annexe")
    .map(tilePath)
    .join("");
  const lieuPublicPath = tiles
    .filter((x) => x.batiment?.categorie === "Lieu Public")
    .map(tilePath)
    .join("");
  const oiPath = tiles
    .filter((x) => x.batiment?.categorie === "Organisation Impériale")
    .map(tilePath)
    .join("");
  const tcastPath = tiles
    .filter((x) => x.batiment?.categorie === "T-Cast")
    .map(tilePath)
    .join("");
  const logementPath = tiles
    .filter((x) => x.batiment?.categorie === "Logement")
    .map(tilePath)
    .join("");
  const emptyPath = tiles
    .filter((x) => x.batiment === null)
    .map(tilePath)
    .join("");
  const searchPath = searchTiles.map(tilePath).join("");

  const viewBoxMin = 3;
  const viewBoxSize = 97;

  const mapRef = useRef();

  return (
    <Box>
      <Button
        onClick={() => setOpenDrawer(true)}
        sx={{ position: "absolute", marginTop: "1rem" }}
      >
        <SearchIcon />
      </Button>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: { width: "40%" },
        }}
      >
        <Search
          searchState={searchParams}
          handleClose={() => setOpenDrawer(false)}
          handleSearch={handleSearch}
          handleResetSearch={handleResetSearch}
        />
      </Drawer>
      <Box ref={mapRef}>
        <svg
          viewBox={`${viewBoxMin} ${viewBoxMin} ${viewBoxSize} ${viewBoxSize}`}
          width={TILE_SIZE * viewBoxSize}
          height={TILE_SIZE * viewBoxSize}
          onMouseMove={handleMouseMove}
          onClick={handleMouseClick}
        >
          <path
            d={cerclePath}
            fill="#e69138"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={entreprisePath}
            fill="#b4a7d6"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={annexePath}
            fill="#a4c2f4"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={lieuPublicPath}
            fill="#a70744"
            stroke="black"
            strokeWidth="0.01"
          />
          <path d={oiPath} fill="#4a86e8" stroke="black" strokeWidth="0.01" />
          <path
            d={tcastPath}
            fill="#a2c4c9"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={logementPath}
            fill="#b6d7a8"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={emptyPath}
            fill="#999999"
            stroke="black"
            strokeWidth="0.01"
          />
          <path
            d={searchPath}
            fill="#FF0000"
            stroke="black"
            strokeWidth="0.01"
          />
        </svg>
      </Box>

      <Popper
        placement="top-start"
        disablePortal={false}
        open={openPopper}
        anchorEl={anchorEl}
        modifiers={[
          {
            name: "flip",
            enabled: false,
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: false,
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [
                (popperPosition?.x ?? 0) - 20,
                -1 * (popperPosition?.y ?? 0),
              ],
            },
          },
        ]}
      >
        <Box
          sx={{
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: "thin",
          }}
        >
          {hoveredTile?.batiment?.nom && (
            <Typography className="typography" sx={{ marginBottom: 0 }}>
              {`#${hoveredTile?.lotId} - ${hoveredTile?.batiment?.nom}`}
            </Typography>
          )}

          <Typography className="typography">{hoveredTile?.adresse.length ? hoveredTile.adresse : "Adresse Inconnue" }</Typography>
        </Box>
      </Popper>
      <DecorationModal
        bitlyId={decorations[clickedTile?.lotId ?? 0]}
        open={showDecorationModal}
        onClose={handleCloseDecoration}
      ></DecorationModal>
      <PresenceModal
        open={showPresenceModal}
        onClose={handleClosePresence}
        lotId={clickedTile?.batiment?.id ?? 0}
      ></PresenceModal>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>
          {clickedTile?.adresse}
          {decorations[clickedTile?.lotId ?? 0] && (
            <Tooltip title="Voir la décoration">
              <IconButton
                onClick={handleShowDecoration}
                sx={{ marginBottom: "0.25rem" }}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Voir les présences">
            <IconButton
              onClick={handleShowPresence}
              sx={{ marginBottom: "0.25rem" }}
            >
              <PersonSearchIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <BuildingForm
            lot={clickedTile}
            handleClose={handleClose}
          ></BuildingForm>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default MapGrid;
