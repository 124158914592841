import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { baseUrl } from "../../constants";
import { useField } from "formik";

export interface AutoCompleteEntry {
  label: string;
  value: number;
}

interface CharacterFieldProps {
  onChange: (value: AutoCompleteEntry) => void;
  defaultValues?: Array<AutoCompleteEntry>;
  name: string;
  label: string;
}

const CharacterField: React.FC<CharacterFieldProps> = ({
  onChange,
  defaultValues,
  name,
  label,
}) => {
  const [axiosInstance] = useAxios();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteEntry[]>(
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOptionsDelayed = useCallback(
    debounce(
      (query: string, callback: (options: AutoCompleteEntry[]) => void) => {
        setAutoCompleteList([]);
        axiosInstance
          .get<AutoCompleteEntry[]>(
            `${baseUrl}/personnages/autocomplete?take=25&query=${query}`
          )
          .then((response) => callback(response.data));
      },
      200
    ),
    []
  );

  useEffect(() => {
    console.log("value changed", value);
    if (value === undefined) return;
    getOptionsDelayed(value, (options: AutoCompleteEntry[]) => {
      setAutoCompleteList(options);
    });
  }, [value, getOptionsDelayed]);

  useEffect(() => {
    if (defaultValues) {
      setAutoCompleteList(defaultValues);
      return;
    }

    axiosInstance
      .get<AutoCompleteEntry[]>(`${baseUrl}/personnages/autocomplete?take=25`)
      .then((response) => setAutoCompleteList(response.data));
  }, []);

  const [field, meta] = useField(name);
  return (
    <Autocomplete
      {...field}
      options={autoCompleteList}
      sx={{ width: "20rem" }}
      onChange={(evt, newValue) => onChange(newValue!)}
      onInputChange={(evt, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} name={name} />
      )}
    />
  );
};

export default CharacterField;
