import { Route, Routes } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CharacterGrid from "../components/CharacterGrid/CharacterGrid";
import MapGrid from "../components/MapGrid/MapGrid";
import ImportationPage from "../components/Importation/ImportationPage";
import Navigation from "../components/Navigation/Navigation";
import ArdalysIcon from "../icons/ardalys.png";
import PrivateRoute from "./PrivateRoute";
import CryoManagerPage from "../components/Cryogenisation/CryoManagerPage";
import CharacterFinderPage from "../components/CharacterFinder/CharacterFinderPage";
import PresencesList from "../components/Presences/PresencesList";
import SectorSelector from "../components/common/SectorSelector";
import SectorContext from "../context/SectorContext";
import { useAuth0 } from "@auth0/auth0-react";

const AppRouter = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const auth = useAuth0();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.loginWithRedirect();
      console.log("signing in");
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowDrawer(open);
    };

  const [sector, setSector] = useState(1);

  const ToggleSector = () => {
    setSector(sector === 1 ? 3 : 1);
  };

  return (
    <>
      <SectorContext.Provider value={{ sector, setSector: ToggleSector }}>
        <AppBar position="fixed" color="secondary" enableColorOnDark>
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={ArdalysIcon}
              alt="ArdalysIcon"
              style={{
                width: "2rem",
                height: "2rem",
                background: "white",
                marginRight: "15px",
                borderRadius: "10px",
              }}
            />
            <Typography variant="h6" color="inherit" component="div">
              Ardalys
            </Typography>
            <Box style={{ marginLeft: "15px" }}>
              <SectorSelector />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer(false)}>
          {Navigation({ toggleDrawer })}
        </Drawer>
        <Box sx={{ marginTop: "3rem" }}>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <MapGrid />
                </PrivateRoute>
              }
            />
            <Route
              path="/persos"
              element={
                <PrivateRoute>
                  <CharacterGrid />
                </PrivateRoute>
              }
            />
            <Route
              path="/importation"
              element={
                <PrivateRoute>
                  <ImportationPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/cryo"
              element={
                <PrivateRoute>
                  <CryoManagerPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/presences"
              element={
                <PrivateRoute>
                  <PresencesList />
                </PrivateRoute>
              }
            />

            <Route
              path="/finder"
              element={
                <PrivateRoute>
                  <CharacterFinderPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
      </SectorContext.Provider>
    </>
  );
};

export default AppRouter;
