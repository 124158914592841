import React, { useContext, useEffect, useMemo, useState } from "react";
import { Personnage } from "../../model/Personnage";
import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_TableOptions,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleFiltersButton,
  MRT_ActionMenuItem,
  
} from "material-react-table";
import forceIcon from "../../icons/stats/Icon_stat_force.png";
import agiliteIcon from "../../icons/stats/Icon_stat_agilite.png";
import formeIcon from "../../icons/stats/Icon_stat_forme.png";
import furtiviteIcon from "../../icons/stats/Icon_stat_furtivite.png";
import informatiqueIcon from "../../icons/stats/Icon_stat_informatique.png";
import ingenierieIcon from "../../icons/stats/Icon_stat_ingenierie.png";
import medecineIcon from "../../icons/stats/Icon_stat_medecine.png";
import perceptionIcon from "../../icons/stats/Icon_stat_perception.png";
import resistanceIcon from "../../icons/stats/Icon_stat_resistance.png";
import santeIcon from "../../icons/stats/Icon_stat_sante.png";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

import type { MRT_ColumnDef } from "material-react-table";
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import SectorContext from "../../context/SectorContext";
import SiIcon from "../../icons/si.png";
import SrIcon from "../../icons/sr.png";

function CharacterGrid() {
  const { sector } = useContext(SectorContext);
  const [personnages, setPersonnages] = useState<Personnage[]>([]);
  const [axiosInstance] = useAxios();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: "etat",
      value: "Actif",
    },
  ]);

  const updateDatabase = React.useCallback(
    async (id: number, updatedObject: Personnage) => {
      await axiosInstance.put(`${baseUrl}/personnage/${id}`, updatedObject);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personnages]
  );

  interface PaginatedResult {
    results: Array<Personnage>;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  }

  const getFilters = () => {
    var filters = {};
    return Object.assign(
      filters,
      ...columnFilters.map((x) => ({ [x.id]: x.value }))
    );
  };

  const updateDatagrid = async () => {
    setIsLoading(true);
    const response = await axiosInstance.get<PaginatedResult>(
      `${baseUrl}/personnages`,
      {
        params: {
          IdSecteur: sector,
          skip: pagination.pageIndex * pagination.pageSize,
          take: pagination.pageSize,
          orderby: sorting[0]?.id ?? "id",
          direction: sorting[0]?.desc ? "desc" : "asc",
          ...getFilters(),
        },
      }
    );
    setTotalCount(response.data.totalCount);
    setPersonnages(response.data.results);
    setIsLoading(false);
  };

  useEffect(() => {
    setPagination((prev) => ({ pageIndex: 0, pageSize: 50 }));
    updateDatagrid();
  }, [sector]);

  useEffect(() => {
    updateDatagrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    columnFilters,
    sector,
  ]);

  const rankList = [
    "Nouvel Arrivant",
    "Résident",
    "Anticitoyen",
    "Citoyen",
    "Noble",
    "Haut Dignitaire",
    "Ambassadeur",
  ];

  const raceList = [
    "Humain",
    "Elfe",
    "Orc",
    "Troll",
    "Nain",
    "Outrilien",
    "Gobelin",
    "Vautour",
    "Androïde",
    "Kobold",
    "Gnoll",
  ];

  const stateList = [
    "Actif",
    "Cryo",
    "Cryo Profonde",
    "Mort",
    "Placeholder",
    "PlaceholderWithId",
  ];

  const genderList = ["Masculin", "Féminin"];

  const PersonnageColumnDefinition = useMemo<MRT_ColumnDef<Personnage>[]>(
    () => [
      {
        accessorKey: "nom",
        header: "Nom",
        filterVariant: "autocomplete",
        size: 115,
        Cell: ({ cell, row }) => {
          return (
            <>
              <Tooltip
                arrow
                sx={{ backgroundColor: "white", color: "black" }}
                hidden={!cell.getValue<string>()?.toString()}
                title={
                  <>
                    {(row?.original as any).avatarUrl && (
                      <img
                        src={(row?.original as any).avatarUrl.toString()}
                        alt="Avatar"
                        width="35rem"
                        height="35rem"
                        style={{ margin: "auto" }}
                      ></img>
                    )}
                  </>
                }
              >
                <Typography sx={{ fontSize: "0.875rem" }}>
                  {cell.getValue<string>()?.toString() ?? ""}
                </Typography>
              </Tooltip>
            </>
          );
        },
      },
      {
        accessorKey: "idPersonnage",
        header: "ID",
        size: 115,
      },
      {
        accessorKey: "idm",
        header: "IDM",
        size: 115,
      },
      {
        accessorKey: "etat",
        header: "État",
        size: 115,
        filterVariant: "select",
        filterSelectOptions: stateList,
        muiEditTextFieldProps: () => ({
          children: stateList.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          )),
          select: true,
        }),
        // valueOptions:,
      },
      {
        accessorKey: "sexe",
        header: "Sexe",
        size: 115,
        filterVariant: "select",
        filterSelectOptions: genderList,
        muiEditTextFieldProps: () => ({
          children: genderList.map((gender) => (
            <MenuItem key={gender} value={gender}>
              {gender}
            </MenuItem>
          )),
          select: true,
        }),
      },

      {
        accessorKey: "metarace",
        header: "Race",
        size: 115,
        filterVariant: "select",
        filterSelectOptions: raceList,
        muiEditTextFieldProps: () => ({
          children: raceList.map((race) => (
            <MenuItem key={race} value={race}>
              {race}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "status",
        header: "Statut",
        size: 115,
        filterVariant: "select",
        filterSelectOptions: rankList,
        muiEditTextFieldProps: () => ({
          children: rankList.map((rank) => (
            <MenuItem key={rank} value={rank}>
              {rank}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "force",
        header: "Force",
        Header: ({ column }) => <img alt="force" src={forceIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "agilite",
        header: "Agilité",
        Header: ({ column }) => <img alt="agilite" src={agiliteIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "resistance",
        header: "Résistance",
        Header: ({ column }) => <img alt="resistance" src={resistanceIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "furtivite",
        header: "Furtivité",
        Header: ({ column }) => <img alt="furtivite" src={furtiviteIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "perception",
        header: "Perception",
        Header: ({ column }) => <img alt="perception" src={perceptionIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "informatique",
        header: "Informatique",
        Header: ({ column }) => (
          <img alt="informatique" src={informatiqueIcon} />
        ),
        size: 35,

        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "ingenierie",
        header: "Ingénierie",
        Header: ({ column }) => <img alt="ingenierie" src={ingenierieIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "medecine",
        header: "Médecine",
        Header: ({ column }) => <img alt="medecine" src={medecineIcon} />,
        size: 35,
      },
      {
        accessorKey: "sante",
        header: "Santé",
        Header: ({ column }) => <img alt="sante" src={santeIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
      {
        accessorKey: "forme",
        header: "Forme",
        Header: ({ column }) => <img alt="forme" src={formeIcon} />,
        size: 35,
        muiEditTextFieldProps: {
          type: "number",
        },
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `export-ardalys-${new Date(Date.now()).toLocaleString("en-US")}`,
    title: "Export Ardalys",
    headers: [
      "ID",
      "IDM",
      "Nom",
      "Sexe",
      "Race",
      "Statut",
      "Etat",
      "Force",
      "Agilité",
      "Résistance",
      "Furtivité",
      "Perception",
      "Informatique",
      "Médecine",
      "Ingénierie",
      "Forme",
      "Santé",
    ],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportToCsv = async () => {
    const response = await axiosInstance.get<PaginatedResult>(
      `${baseUrl}/personnages`,
      {
        params: {
          IdSecteur: 1,
          skip: 0,
          take: 25000,
          orderby: sorting[0]?.id ?? "id",
          direction: sorting[0]?.desc ? "desc" : "asc",
          ...getFilters(),
        },
      }
    );
    csvExporter.generateCsv(
      response.data.results.map((personnage) => {
        const { id, avatarUrl, ...dataToExport } = personnage;
        return dataToExport;
      })
    );
  };

  const handleSaveRow: MRT_TableOptions<Personnage>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      await updateDatabase((row?.original as any).id, {
        ...row?.original,
        ...values,
      });
      exitEditingMode(); //required to exit editing mode
      await updateDatagrid();
    };

    const transfugeCharacter = async (id: number) => {
      await axiosInstance.post(`${baseUrl}/personnage/${id}/transfuge`);
      await updateDatagrid();
    }

  const table = useMaterialReactTable({
    columns: PersonnageColumnDefinition,
    data: personnages,
    enableRowSelection: true, //table options as options to this hook
    enableDensityToggle: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false, //SOON :)
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    layoutMode: "grid-no-grow",
    localization: MRT_Localization_FR,
    positionActionsColumn: "last",
    renderRowActionMenuItems: ({ row }) => [
      <MRT_ActionMenuItem
      icon={ <img src={sector === 1 ? SrIcon : SiIcon} height={20} width={20}/>}
      key="transfuge"
      label="Transfuge"
      onClick={() => transfugeCharacter((row?.original as any).id)}
      table={table}
    />,
    ],
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Tooltip title="Export to CSV">
          <IconButton onClick={handleExportToCsv}>
            <BorderAllIcon />
          </IconButton>
        </Tooltip>

        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    columnFilterDisplayMode: "popover",
    onColumnFiltersChange: setColumnFilters,
    state: {
      sorting,
      pagination,
      density: "compact",
      columnFilters,
      isLoading,
    },
    onPaginationChange: setPagination,
    rowCount: totalCount,
    editDisplayMode: "modal",
    enableEditing: true,
    onSortingChange: setSorting,
    onEditingRowSave: handleSaveRow,
    enableColumnFilters: true,
    enableStickyFooter: true,
    muiTableContainerProps: { sx: { maxHeight: "47rem" } },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
}

export default CharacterGrid;
