import React, { useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Poste } from "../../model/Poste";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";
import CharacterField, { AutoCompleteEntry } from "../common/CharacterField";

interface UserAddModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  batimentId: number;
  handleAddMember: (poste: Poste) => void;
}

function UserAddModal({
  open,
  setOpen,
  batimentId,
  handleAddMember,
}: UserAddModalProps): JSX.Element {
  const [characterId, setCharacterId] = useState<number | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);
  const [axiosInstance] = useAxios();

  const handleClose = () => {
    setCharacterId(undefined);
    setRole(undefined);
    setOpen(false);
  };

  const AddMember = async () => {
    if (characterId === 0) return;
    const result = await axiosInstance.post<Poste>(`${baseUrl}/poste`, {
      batimentId,
      nomPoste: role,
      personnageId: characterId,
    });
    if (result) handleClose();
    handleAddMember(result.data);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography>Ajouter un membre</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <CharacterField
            name="character"
            onChange={(newValue: AutoCompleteEntry) =>
              setCharacterId(newValue.value)
            }
            label="Nom du membre"
          />
          <TextField
            label="Role du membre"
            fullWidth
            placeholder="Role"
            variant="standard"
            value={role}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRole(event.target.value)
            }
          ></TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={AddMember}
          disabled={!characterId || !role}
          color="primary"
        >
          Confirmer
        </Button>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserAddModal;
