import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const baseDomain = process.env.VUE_APP_API_URL;

const baseURL = `${baseDomain}`;

const useAxios = () => {
const auth = useAuth0();

const instance = axios.create({
  baseURL
});
instance.interceptors.request.use(
  async (config: any) => {
    const token = await auth.getAccessTokenSilently();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

return [instance];
}

export default useAxios;
