import { TextField, Box, Button, Grid, Drawer } from "@mui/material";
import React, { useContext, useState } from "react";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";
import CommandGenerator from "./CommandGenerator";
import SectorContext from "../../context/SectorContext";

interface CommandResult {
  success: boolean;
  command: string;
  target: string;
  message: string;
}

interface CommandPayload {
  command: string;
  target: string;
  targetType: string;
  argument?: string;
  results: Array<string>;
  idSector: number;
}

const ImportationPage = () => {
  const [content, setContent] = useState("");
  const [results, setResults] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [axiosInstance] = useAxios();
  const { sector } = useContext(SectorContext);

  const importType = {
    Building: "Building",
    Character: "Character",
  };

  const showResults = (data: Array<CommandResult>) => {
    if (!data.length) return;
    setResults(
      results +
        "\n" +
        data
          .map(
            (x) =>
              `${x.command} ${x.target}: ${x.success ? " SUCCÈS" : "ÉCHEC"} ${
                x.message ? `(${x.message})` : ""
              }`
          )
          .join("\n")
    );
  };

  const hasCommandFailed = (command: string, results: Array<string>) => {
    const firstCommand = results[0];
    if (
      firstCommand.toLocaleLowerCase() ===
      "Votre niveau en informatique est trop faible pour réussir cette commande".toLocaleLowerCase()
    )
      return false;
    if (
      firstCommand.toLocaleLowerCase() ===
      "La commande a échoué.".toLocaleLowerCase()
    )
      return false;
    if (
      /(.*)n'existe pas/.test(firstCommand.toLocaleLowerCase()) &&
      command.toLocaleLowerCase() !== "action".toLocaleLowerCase()
    )
      return false;
    if (
      command.toLocaleLowerCase() === "presences" &&
      firstCommand.toLocaleLowerCase() === "0"
    )
      return false;
    if (!firstCommand) return false;
    return true;
  };

  const extractIdLieu = (header: string) => {
    const [, , , idLieu] = header.split(/([a-zA-Z]*)@(lieu)?([0-9.]*):/);
    return idLieu;
  };

  const isCommandUseless = (command: string) => {
    return ["quit", "connect"].includes(command);
  };

  const getCommandPayload = (
    command: string,
    target: string,
    idLieu: string
  ) => {
    switch (command) {
      case "asrt":
      case "action":
      case "nomposte":
      case "sauvegarde":
      case "idpersonnage":
      case "habitations":
      case "sante":
      case "forme":
      case "statut":
        return { command, target, targetType: importType.Character };

      case "batiment":
      case "infos":
      case "presences":
        return { command, target: idLieu, targetType: importType.Building };

      case "camera":
      case "digicode":
        return {
          command,
          target: idLieu,
          targetType: importType.Building,
          argument: target,
        };
      default:
        return { command, target, targetType: importType.Character };
    }
  };

  const HandleSubmit = async (event: any) => {
    const header = content.toLowerCase().includes("deck")
      ? /(deck )/
      : /(.*@.*: )/;
    const imports = content
      .toLowerCase()
      .split(header)
      .splice(1)
      .map((_, i, all) => all.slice(2 * i, 2 * i + 2))
      .filter((x) => x.length)
      .map((x) => ({
        header: x[0],
        command: x[1].split("\n").filter((x) => x !== ""),
      }))
      .filter((x) => x.command.length > 1)
      .map((x) => {
        const [typedCommand, ...results] = x.command;
        const [command, , target] = typedCommand.split(/\s+(-[a-zA-Z])?/);
        const idLieu = extractIdLieu(x.header);
        console.log(idLieu);
        const payload = {
          ...getCommandPayload(command.toLowerCase(), target, idLieu),
          results,
          idSector: sector
        } satisfies CommandPayload;
        return payload;
      })
      .filter(
        (x) =>
          hasCommandFailed(x.command, x.results) && !isCommandUseless(x.command)
      );
    
    const result = await axiosInstance.post(`${baseUrl}/import`, {
      imports: imports,
    });
    setContent("");
    showResults(result.data);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            label="Insérer la sortie du deck ici"
            fullWidth
            variant="filled"
            multiline
            minRows={30}
            value={content}
            onChange={(newValue) => setContent(newValue.target.value)}
            maxRows={30}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Résultat des importations"
            fullWidth
            disabled
            variant="filled"
            multiline
            minRows={30}
            value={results}
            onChange={(newValue) => setContent(newValue.target.value)}
            maxRows={30}
          ></TextField>
        </Grid>
      </Grid>
      <Button onClick={HandleSubmit}>Soumettre</Button>
      <Button onClick={() => setDrawerOpen(true)}>Générer des commandes</Button>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        PaperProps={{
          sx: { width: "45%", overflowX: "hidden" },
        }}
        onClose={() => setDrawerOpen(false)}
      >
        <CommandGenerator />
      </Drawer>
    </Box>
  );
};

export default ImportationPage;
