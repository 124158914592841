import { Cercle } from "../../../model/models";
import { FormControlLabel, Box, Switch, Grid } from "@mui/material";
import "./CercleScreen.css";
import TextField from "../../common/Textfield";
import ZoneSelector from "../../common/ZoneSelector";
import PosteForm from "../PosteForm/PosteForm";

interface CercleScreenProps {
  batiment: Cercle;
  handleChange: any;
  setFieldValue: any;
}

const CercleScreen = ({
  batiment,
  handleChange,
  setFieldValue,
}: CercleScreenProps) => {
  const handleSwitchChange = (event: any) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setFieldValue(name, checked);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <TextField
            name="batiment.sousStructures.0.digicode"
            label="Digicode"
            variant="standard"
            inputProps={{ maxLength: 7 }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Switch
                checked={batiment.sousStructures[0].isDigicodeCondor}
                name="batiment.sousStructures.0.isDigicodeCondor"
                onChange={handleSwitchChange}
                color="primary"
              />
            }
            label="Condor"
            labelPlacement="start"
            className="switch"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Switch
                checked={batiment.sousStructures[0].hasCamera}
                onChange={handleSwitchChange}
                name="batiment.sousStructures.0.hasCamera"
                color="primary"
              />
            }
            label="Camera"
            labelPlacement="start"
            className="switch"
          />
        </Grid>
        <Grid item xs={3} sx={{ marginTop: "0.75rem" }}>
          {/* <Link>Présences</Link> */}
        </Grid>
        <br />
        <Grid item xs={12} sx={{ marginTop: "1rem" }}>
          <ZoneSelector
            name="batiment.sousStructures.0.zones"
            value={batiment.sousStructures[0].zones ?? []}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
      <PosteForm batimentId={batiment.id ?? 0}></PosteForm>
    </Box>
  );
};

export default CercleScreen;
