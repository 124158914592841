import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

interface ZoneSelectorProps {
  name: string;
  value: Array<boolean>;
  setFieldValue: any;
}
const ZoneSelector = ({ name, value, setFieldValue }: ZoneSelectorProps) => {
  const handleChange = (event: any) => {
    const index = Number(event.target.labels[0].innerText) - 1;
    value[index] = !value[index];
    setFieldValue(name, value);
  };

  return (
    <Box>
      <Typography>Zones</Typography>
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[0]} />}
        label="1"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[1]} />}
        label="2"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[2]} />}
        label="3"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[3]} />}
        label="4"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[4]} />}
        label="5"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[5]} />}
        label="6"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[6]} />}
        label="7"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[7]} />}
        label="8"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
      <FormControlLabel
        value="bottom"
        control={<Checkbox color="primary" checked={value[8]} />}
        label="9"
        className="checkbox"
        labelPlacement="bottom"
        style={{ margin: "0px" }}
        onChange={handleChange}
      />
    </Box>
  );
};

export default ZoneSelector;
