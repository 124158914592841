// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
    margin-top: 3%;
    margin-bottom: -5%;
}`, "",{"version":3,"sources":["webpack://./src/components/BuildingForm/LogementScreen/LogementScreen.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".switch {\n    margin-top: 3%;\n    margin-bottom: -5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
