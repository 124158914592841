import { Grid, TextField, Typography, Button, Box } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSelectionModel,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";

interface CharacterFinderProps {}

interface Entry {
  id: string;
  index: number;
  command: string;
}

const CharacterFinderPage = ({}: CharacterFinderProps) => {
  const [input, setInput] = useState("");
  const [axiosInstance] = useAxios();
  const [targets, setTargets] = useState<Array<Entry>>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const CommandColumnDefinition: GridColDef[] = [
    {
      field: "command",
      headerName: "Commande",
      width: 200,
      resizable: true,
      editable: false,
      filterable: false,
    },
  ];

  const handleSelectionChange = (params: GridCellParams, event: any) => {
    if (event.key === "ArrowDown" && params.row.index !== targets.length - 1) {
      const newSelection = targets[params.row.index + 1].id;
      setSelectionModel([newSelection]);
    }

    if (event.key === "ArrowUp" && params.row.index !== 0) {
      const newSelection = targets[params.row.index - 1].id;
      setSelectionModel([newSelection]);
    }
  };

  const handleSubmit = async () => {
    var split = input.replaceAll(", ", "\n").split("\n");
    const results = await axiosInstance.post<Array<string>>(
      `${baseUrl}/personnages/diff`,
      {
        characters: [...split],
      }
    );
    setTargets(
      results.data.map((target, index) => ({
        id: target,
        index,
        command: `idpersonnage ${target}`,
      }))
    );
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          label="Entrez la liste des personnages ici"
          variant="filled"
          fullWidth
          multiline
          value={input}
          onChange={(event) => setInput(event.target.value)}
          minRows={30}
          maxRows={30}
        ></TextField>
      </Grid>
      <Grid item xs={6} sx={{ borderBottom: "solid thin" }}>
        {targets.length > 0 && (
          <Box>
            <Typography variant="body1" sx={{ margin: "5px" }}>
              Personnages à ajouter
            </Typography>
            <DataGrid
              sx={{ margin: "3rem", marginTop: 0, height: "40rem" }}
              rows={targets}
              columns={CommandColumnDefinition}
              selectionModel={selectionModel}
              rowHeight={38}
              onCellKeyDown={handleSelectionChange}
              onSelectionModelChange={setSelectionModel}
            />
          </Box>
        )}
      </Grid>
      <Button onClick={handleSubmit}>Soumettre</Button>
    </Grid>
  );
};

export default CharacterFinderPage;
