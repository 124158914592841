import "./App.css";
import { ThemeProvider } from "@emotion/react";
import AppTheme from "./theme/theme";

import AppRouter from "./router/AppRouter";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";

function App() {
  return (
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>  
        <AppContent />
      </BrowserRouter>
    </ThemeProvider>
  );
}

const AppContent = () => {

  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };
  
  // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
  // for a full list of the available properties on the provider
  const config = getConfig();
  
  const providerConfig = {
    domain: config.domain!,
    clientId: config.clientId!,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(config.audience ? { audience: config.audience } : null),
    },
  };

  return (
    <Auth0Provider {...providerConfig}>
      <AppRouter />
    </Auth0Provider>
  );
};

export default App;
