import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

interface UserAddModalProps {
  open: boolean;
  onClose: () => void;
  bitlyId: string;
}

function DecorationModal({
  open,
  onClose,
  bitlyId,
}: UserAddModalProps): JSX.Element {
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography>Décoration</Typography>
      </DialogTitle>
      <DialogContent>
        <>
          <img src={`https://bit.ly/${bitlyId}`} alt="deco" />
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Retour
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DecorationModal;
