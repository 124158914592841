import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import MapIcon from "@mui/icons-material/Map";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DownloadIcon from "@mui/icons-material/Download";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface NavigationProp {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const Navigation = ({ toggleDrawer }: NavigationProp) => {
  const auth = useAuth0();

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem component={Link} to="/" button key="Carte">
          <ListItemIcon>
            <IconButton>
              <MapIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Carte" />
        </ListItem>
        <ListItem button component={Link} to="/persos" key="Liste">
          <ListItemIcon>
            <IconButton>
              <ListAltIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Habitants" />
        </ListItem>
        <ListItem button component={Link} to="/importation" key="Importation">
          <ListItemIcon>
            <IconButton>
              <DownloadIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Importation" />
        </ListItem>
        <ListItem button component={Link} to="/presences" key="Presences">
          <ListItemIcon>
            <IconButton>
              <PersonSearchIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Présences" />
        </ListItem>
        <ListItem button component={Link} to="/cryo" key="Cryo">
          <ListItemIcon>
            <IconButton>
              <AcUnitIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Cryogénies" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/finder"
          key="Ajouts de Personnages"
        >
          <ListItemIcon>
            <IconButton>
              <PersonAddIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Ajouts de Personnages" />
        </ListItem>
        <ListItemButton onClick={async () => {
          await auth.logout(); 
        }} key="logout">
          <ListItemIcon>
            <IconButton>
              <LogoutIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default Navigation;
