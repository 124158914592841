import "./BuildingForm.css";
import { Case, Cercle, Logement, Organisation } from "../../model/models";
import LogementScreen from "./LogementScreen/LogementScreen";
import CercleScreen from "./CercleScreen/CercleScreen";
import { Formik, Form, FormikValues } from "formik";
import TextField from "../common/Textfield";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { baseUrl } from "../../constants";
import useAxios from "../../hooks/useAxios";

interface BuildingFormProps {
  lot: Case | undefined;
  handleClose: any;
}

const BuildingForm = ({ lot, handleClose }: BuildingFormProps) => {
  const [axiosInstance] = useAxios();

  const UpdateBuilding = async (values: Case) => {
    await axiosInstance.put<Case>(`${baseUrl}/batiment/${values.id}`, {
      ...values.batiment,
      lotId: values.lotId,
      id: values.id,
      notes: values.notes,
      adresse: values.adresse,
    });
  };

  const handleCategorieChange = (event: any, setFieldValue: any) => {
    const value = event.target.value;
    if (value === "Entreprise" || value === "Organisation Impériale")
      setFieldValue("batiment", getDefaultOrganisation(event.target.value));
    if (value === "Cercle" || value === "Annexe")
      setFieldValue("batiment", getDefaultCercle(event.target.value));
    if (value === "Logement")
      setFieldValue("batiment", getDefaultLogement(event.target.value));
    if (value === undefined) setFieldValue("batiment", undefined);
  };

  const getDefaultLogement = (categorie: string): Logement => {
    return {
      discriminator: "Logement",
      sousStructures: [],
      proprietaires: [],
      nom: "",
      categorie,
      id: lot?.batiment?.id,
    };
  };

  const getDefaultCercle = (categorie: string): Cercle => {
    return {
      discriminator: "Cercle",
      sousStructures: [
        {
          digicode: "*******",
          hasCamera: true,
          isDigicodeCondor: true,
          zones: new Array(9).fill(false),
        },
      ],
      proprietaires: [],
      nom: "",
      categorie,
      id: lot?.batiment?.id,
    };
  };

  const getDefaultOrganisation = (categorie: string): Organisation => {
    return {
      discriminator: "Organisation",
      sousStructures: [
        {
          digicode: "*******",
          hasCamera: true,
          isDigicodeCondor: true,
          zones: new Array(9).fill(false),
        },
      ],
      proprietaires: [],
      nom: "",
      categorie,
      id: lot?.batiment?.id,
    };
  };

  return (
    <Formik
      initialValues={lot as FormikValues}
      onSubmit={async (values) => {
        await UpdateBuilding(values as Case);
        await handleClose();
      }}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <TextField name="adresse" label="Adresse" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextField name="lotId" label="Id Lieu" disabled />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Type de bâtiment
                </InputLabel>
                <Select
                  onChange={(event: any) => {
                    handleChange(event);
                    handleCategorieChange(event, setFieldValue);
                  }}
                  label="Categorie"
                  value={values.batiment?.categorie}
                  fullWidth={true}
                  variant="standard"
                >
                  <MenuItem value={undefined}>Terrain Vague</MenuItem>
                  <MenuItem value="Cercle">Cercle</MenuItem>
                  <MenuItem value="Logement">Logement</MenuItem>
                  <MenuItem value="Entreprise">Entreprise</MenuItem>
                  <MenuItem value="Annexe">Annexe</MenuItem>
                  <MenuItem value="Lieu Public">Lieu Public</MenuItem>
                  <MenuItem value="Organisation Impériale">
                    Organisation Impériale
                  </MenuItem>
                  <MenuItem value="T-Cast">T-Cast</MenuItem>
                  <MenuItem value="" hidden />
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="batiment.nom"
                disabled={values?.batiment?.categorie === "Lieu Public"}
                label="Nom du bâtiment"
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <TextField
                name={`notes`}
                value={values?.notes}
                onChange={handleChange}
                variant="outlined"
                label="Notes"
                multiline
                fullWidth
                maxRows={3}
                minRows={1}
              />
            </Grid>
          </Grid>
          <br />
          {values?.batiment?.discriminator === "Logement" ? (
            <LogementScreen
              batiment={values.batiment as Logement}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            ></LogementScreen>
          ) : values?.batiment?.discriminator === "Cercle" ||
            (values?.batiment?.discriminator === "Organisation" &&
              values?.batiment?.categorie !== "Lieu Public") ? (
            <CercleScreen
              batiment={values.batiment as Cercle}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            ></CercleScreen>
          ) : (
            <div></div>
          )}
          <Button type="submit" color="primary">
            Modifier
          </Button>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default BuildingForm;
