import { FormControlLabel, Switch, styled } from "@mui/material";
import SiIcon from "../../icons/si.png";
import SrIcon from "../../icons/sr.png";
import { useContext } from "react";
import SectorContext from "../../context/SectorContext";

const SectorSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${SrIcon})`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${SiIcon})`,
      backgroundSize: "20px",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const SectorSelector = () => {
  const { sector, setSector } = useContext(SectorContext);

  return (
    <FormControlLabel
      control={
        <SectorSwitch
          checked={sector === 3}
          onChange={() => {
            console.log(sector, "SECTOR");
            sector === 1 ? setSector(3) : setSector(1);
          }}
        />
      }
      label=""
    />
  );
};

export default SectorSelector;
