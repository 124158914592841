import {
  Box,
  Button,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import TextField from "../../common/Textfield";

interface SearchProps {
  searchState: any;
  handleClose: () => void;
  handleSearch: (values: any) => void;
  handleResetSearch: () => void;
}

type SearchParams = {
  request: string;
  proprietaire: boolean;
  locataire: boolean;
  membre: boolean;
  employe: boolean;
  presence: boolean;
  note: boolean;
};

const Search = ({
  searchState,
  handleClose,
  handleSearch,
  handleResetSearch,
}: SearchProps) => {
  return (
    <Formik
      initialValues={searchState}
      onReset={() => {
        handleResetSearch();
        handleClose();
      }}
      onSubmit={(values) => {
        handleSearch(values);
        handleClose();
      }}
    >
      {({ values, setFieldValue, submitForm, resetForm }) => {
        const handleChange = (event: any) => {
          const name = event.target.name;
          setFieldValue(name, event.target.checked);
        };

        return (
          <Box>
            <Grid container sx={{ margin: "3rem" }}>
              <Grid item xs={12}>
                <Typography sx={{ marginTop: "15px" }} variant="h5">
                  Rechercher dans la carte
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ marginTop: "15px" }}
                  variant="standard"
                  label="Valeur"
                  name="request"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={2} />
              <Grid container item xs={6} sx={{ marginTop: "15px" }}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={searchState.locataire} />
                    }
                    label="Locataire"
                    name="locataire"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked={searchState.proprietaire} />
                    }
                    label="Propriétaire"
                    name="proprietaire"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.membre} />}
                    label="Membres"
                    name="membre"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.employe} />}
                    label="Employés"
                    name="employe"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.presence} />}
                    label="Présences"
                    name="presence"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.note} />}
                    label="Notes"
                    name="note"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.adresse} />}
                    label="Adresse"
                    name="adresse"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={searchState.nom} />}
                    label="Nom du bâtiment"
                    name="nom"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={6} />

              <Grid item xs={6}>
                <Button
                  sx={{ marginTop: "15px" }}
                  onClick={submitForm}
                  variant="contained"
                >
                  Rechercher
                </Button>
                <Button
                  sx={{ marginLeft: "10px", marginTop: "15px" }}
                  onClick={() => resetForm({})}
                  variant="contained"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

export default Search;
